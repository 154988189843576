<template>
  <div>
    <a-modal
      v-model="config.show"
      style="padding: 0px; margin-top: 0px"
      :width="config.width"
      :title="config.title"
      :okText="config.okText"
      @ok="handleOk"
      @cancel="handleCancel"
      :confirm-loading="confirmLoading"
     
    >
      <template slot="footer">
        <a-button v-if="config.type === 'edit'" @click="handleCancel">
          取消
        </a-button>

        <a-button
          v-if="config.type === 'edit'"
          key="submit"
          type="primary"
          @click="handleOk"
        >
          确定
        </a-button>
           <a-button v-if="config.type === 'look'" @click="handleCancel">
          关闭
        </a-button>
      </template>

      <div v-if="config.type === 'del'">
        <div class="modal_text">
          <img src="../../assets/warn.png" style="width: 26px" alt="" />
          <span class="modal_logo">是否删除此等级?</span>
        </div>
        <div class="modal_text modal_stext">
          删除等级后将无法恢复且门户将自动下架此套餐
        </div>
      </div>
      <div
        v-if="
          config.type === 'add' ||
          config.type === 'edit' ||
          config.type === 'look'
        "
      >
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="等级名称" prop="levelName">
            <a-input
              placeholder="请输入"
              class="input_width"
              v-model="form.levelName"
              :disabled="config.type === 'look'"
            />
          </a-form-model-item>
          <a-form-model-item label="版本类型" prop="versionType">
            <a-select
              @change="versionTypeChange"
              v-model="form.versionType"
              class="input_width"
              :disabled="config.type === 'look'"
            >
              <a-select-option
                v-for="(item, index) in versionTypeList"
                :key="index"
                :value="item.value"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            v-if="form.versionType === 1"
            label="用户数上限"
            prop="maxUsers"
          >
            <div class="form_item_contain">
              <div class="form_item_left">
                <span class="form_item_span">面议</span>
                <a-switch
                  v-model="maxUsers"
                  @change="switchChange($event, 'maxUsers')"
                  :disabled="config.type === 'look'"
                />
              </div>
              <div>
                <a-input-number
                  :disabled="maxUsers || config.type === 'look'"
                  placeholder="请输入"
                  style="width: 230px"
                  v-model="form.maxUsers"
                  :step="1"
                  :min="0"
                  :precision="0"
                />
              </div>
            </div>
          </a-form-model-item>
          <a-form-model-item
            v-if="form.versionType === 1"
            label="外部应用数上限"
            prop="maxApps"
          >
            <div class="form_item_contain">
              <div class="form_item_left">
                <span class="form_item_span">面议</span>
                <a-switch
                  v-model="maxApps"
                  @change="switchChange($event, 'maxApps')"
                  :disabled="config.type === 'look'"
                />
              </div>
              <div>
                <a-input-number
                  :disabled="maxApps || config.type === 'look'"
                  placeholder="请输入"
                  style="width: 230px"
                  v-model="form.maxApps"
                  :step="1"
                  :min="0"
                  :precision="0"
                />
              </div>
            </div>
          </a-form-model-item>
          <a-form-model-item
            v-if="form.versionType === 2"
            label="CPU(核)"
            prop="maxCpu"
          >
            <div class="form_item_contain">
              <div class="form_item_left">
                <span class="form_item_span">面议</span>
                <a-switch
                  v-model="maxCpu"
                  @change="switchChange($event, 'maxCpu')"
                  :disabled="config.type === 'look'"
                />
              </div>
              <div>
                <a-select
                  v-model="form.maxCpu"
                  :disabled="maxCpu || config.type === 'look'"
                  style="width: 230px"
                >
                  <a-select-option
                    v-for="(item, index) in cpuList"
                    :key="index"
                    :value="item"
                  >
                    {{ item }}
                  </a-select-option>
                </a-select>
              </div>
            </div>
          </a-form-model-item>
          <a-form-model-item
            label="内存(G)"
            v-if="form.versionType === 2"
            prop="maxMemory"
          >
            <div class="form_item_contain">
              <div class="form_item_left">
                <span class="form_item_span">面议</span>
                <a-switch
                  v-model="maxMemory"
                  @change="switchChange($event, 'maxMemory')"
                  :disabled="config.type === 'look'"
                />
              </div>
              <div>
                <a-select
                  :disabled="maxMemory || config.type === 'look'"
                  v-model="form.maxMemory"
                  style="width: 230px"
                >
                  <a-select-option
                    v-for="(item, index) in memoryList"
                    :key="index"
                    :value="item"
                  >
                    {{ item }}
                  </a-select-option>
                </a-select>
              </div>
            </div>
          </a-form-model-item>
          <a-form-model-item
            label="存储空间(G)"
            v-if="form.versionType === 2"
            prop="maxStorage"
          >
            <div class="form_item_contain">
              <div class="form_item_left">
                <span class="form_item_span">面议</span>
                <a-switch
                  v-model="maxStorage"
                  @change="switchChange($event, 'maxStorage')"
                  :disabled="config.type === 'look'"
                />
              </div>
              <div>
                <a-input-number
                  :disabled="maxStorage || config.type === 'look'"
                  placeholder="请输入"
                  style="width: 230px"
                  v-model="form.maxStorage"
                  :step="1"
                  :min="0"
                  :precision="0"
                />
              </div>
            </div>
          </a-form-model-item>
          <a-form-model-item
            v-if="form.versionType === 2 || form.versionType === 1"
            label="价格(/月)"
            prop="price"
          >
            <div class="form_item_contain">
              <div class="form_item_left">
                <span class="form_item_span">面议</span>
                <a-switch
                  v-model="price"
                  @change="switchChange($event, 'price')"
                  :disabled="config.type === 'look'"
                />
              </div>
              <div>
                <a-input-number
                  :disabled="price || config.type === 'look'"
                  placeholder="请输入"
                  style="width: 230px"
                  v-model="form.price"
                  :step="0.01"
                  :min="0"
                  @change="priceChange"
                  :precision="2"
                  :formatter="limitDecimals"
                  :parser="limitDecimals"
                />
              </div>
            </div>
          </a-form-model-item>
          <a-form-model-item
            v-if="form.versionType === 2 || form.versionType === 1"
            label="体验版"
            prop="trialVersion"
          >
            <div class="form_item_contain">
              <div class="form_item_left">
                <a-switch
                  style="margin-left: 48px"
                  v-model="form.trialVersion"
                  :disabled="config.type === 'look'"
                />
              </div>
              <div>
                <!-- <a-input-number
                  :disabled="price"
                  placeholder="请输入"
                  style="width: 230px"
                  v-model="form.price"
                  :step="0.01"
                  :min="0"
                  @change="priceChange"
                  :precision="2"
                  :formatter="limitDecimals"
                  :parser="limitDecimals"
                /> -->
              </div>
            </div>
          </a-form-model-item>
          <!-- <a-form-model-item
            label="优惠情况"
            v-if="form.versionType === 2 || form.versionType === 1"
            prop="discount"
          >
            <a-select v-model="form.discount" class="input_width">
              <a-select-option
                v-for="(item, index) in discountList"
                :key="index"
                :value="item"
              >
                {{ item }}
              </a-select-option>
            </a-select>
          </a-form-model-item> -->
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { sassGradeApi } from "@/api/sassGrade.js";

export default {
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          type: "",
          id: 0,
          show: false,
          record: null,
          title: "",
          width: "60%",
          okText: "",
        };
      },
    },
  },
  data() {
    //校验手机号
    // let validatorPhone = (rule, value, callback) => {
    //   if (!value) {
    //     callback(new Error("手机号码不能为空"));
    //   } else if (!/^1[3456789]\d{9}$/.test(value) && value) {
    //     callback(new Error("手机号码格式不正确"));
    //   } else {
    //     callback();
    //   }
    // };
    return {
      loading: false,
      confirmLoading: false,
      maxUsers: false,
      maxApps: false,
      maxCpu: false,
      maxMemory: false,
      maxStorage: false,
      price: false,
      rowVersion: null,
      cpuList: [1, 2, 4, 8],
      memoryList: [1, 2, 4, 8, 16, 32, 64, 128],
      versionTypeList: [
        {
          name: "标准版",
          value: 1,
        },
        {
          name: "专业版",
          value: 2,
        },
      ],
      discountList: ["无", "一个月", "三个月", "半年", "一年"],
      labelCol: { span: 6 },
      wrapperCol: { span: 15 },
      form: {
        levelName: "",
        versionType: "",
        maxUsers: "",
        maxApps: "",
        maxCpu: "",
        maxMemory: "",
        maxStorage: "",
        price: "",
        discount: "",
        trialVersion: false,
      },
      rules: {
        levelName: [
          {
            required: true,
            message: "等级名称不能为空",
            trigger: "change",
          },
          {
            pattern: /^(?!_|[0-9])[a-zA-Z0-9_\u4e00-\u9fa5]{2,50}$/,
            message:
              "仅支持中文、英文、数字、下划线（_），且只能以英文或中文开头，2~50个字符",
            trigger: "blur",
          },
        ],
        versionType: [
          {
            required: true,
            message: "版本类型不能为空",
            trigger: "change",
          },
        ],
        maxUsers: [
          {
            required: true,
            message: "用户上限不能为空",
            trigger: "change",
          },
        ],
        maxApps: [
          {
            required: true,
            message: "外部应用数上限不能为空",
            trigger: "blur",
          },
        ],
        maxCpu: [
          {
            required: true,
            message: "CPU(核)不能为空",
            trigger: "blur",
          },
        ],
        maxMemory: [
          {
            required: true,
            message: "内存(G)不能为空",
            trigger: "blur",
          },
        ],
        maxStorage: [
          {
            required: true,
            message: "存储空间(G)不能为空",
            trigger: "blur",
          },
        ],
        price: [
          {
            required: true,
            message: "价格不能为空",
            trigger: "blur",
          },
        ],
        discount: [
          {
            required: true,
            message: "优惠情况不能为空",
            trigger: "blur",
          },
        ],
        trialVersion: [
          {
            required: true,
            message: "体验版不能为空",
            trigger: "blur",
          },
        ],
        levelStatus: null,
      },
    };
  },
  mounted() {
    this.inintModal();
  },
  created() {},
  watch: {},
  methods: {
    inintModal() {
      if (this.config.type === "edit" || this.config.type === "look") {
        this.form.discount = this.config.record.discount;
        this.form.levelName = this.config.record.levelName;
        this.form.versionType = this.config.record.versionType;
        this.rowVersion = this.config.record.rowVersion;
        this.levelStatus = this.config.record.levelStatus;
        if (this.config.record.maxApps === -1) {
          this.maxApps = true;
          this.form.maxApps = "";
          this.$set(this.rules.maxApps[0], "required", false);
        } else {
          this.maxApps = false;
          this.form.maxApps = this.config.record.maxApps;
        }
        if (this.config.record.maxCpu === -1) {
          this.maxCpu = true;
          this.form.maxCpu = "";
          this.$set(this.rules.maxCpu[0], "required", false);
        } else {
          this.maxCpu = false;
          this.form.maxCpu = this.config.record.maxCpu;
        }
        if (this.config.record.maxMemory === -1) {
          this.maxMemory = true;
          this.form.maxMemory = "";
          this.$set(this.rules.maxMemory[0], "required", false);
        } else {
          this.maxMemory = false;
          this.form.maxMemory = this.config.record.maxMemory;
        }
        if (this.config.record.maxStorage === -1) {
          this.maxStorage = true;
          this.form.maxStorage = "";
          this.$set(this.rules.maxStorage[0], "required", false);
        } else {
          this.maxStorage = false;
          this.form.maxStorage = this.config.record.maxStorage;
        }
        if (this.config.record.maxUsers === -1) {
          this.maxUsers = true;
          this.form.maxUsers = "";
          this.$set(this.rules.maxUsers[0], "required", false);
        } else {
          this.maxUsers = false;
          this.form.maxUsers = this.config.record.maxUsers;
        }
        if (this.config.record.price === "面议") {
          this.price = true;
          this.form.price = "";
          this.$set(this.rules.price[0], "required", false);
        } else {
          this.price = false;
          this.form.price = this.config.record.price;
        }
        if (this.config.record.trialVersion === 1) {
          this.form.trialVersion = true;
        } else {
          this.form.trialVersion = false;
        }
      }
    },
    versionTypeChange(val) {
      console.log(val);
      if (val === 1) {
        this.form.maxUsers = "";
        this.form.maxApps = "";
        this.form.price = null;
        this.form.trialVersion = false;
      } else {
        this.form.maxCpu = "";
        this.form.maxMemory = "";
        this.form.maxStorage = "";
        this.form.price = null;
        this.form.trialVersion = false;
      }
    },
    limitDecimals(val) {
      return val.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
    },
    handleCancel() {
      this.$emit("handleCancel");
    },
    //时间修改事件
    onTimeChange(dates, dateStrings) {
      this.form.revisitDay = dateStrings;
    },

    switchChange(checked, event) {
      if (checked) {
        this.form[event] = "";
        this.$set(this.rules[event][0], "required", false);
      } else {
        this.form[event] = "";
        this.$set(this.rules[event][0], "required", true);
      }
    },
    priceChange(val) {
      this.form.price = val;
    },
    //创建等级
    addGrade() {
      this.confirmLoading = true;
      let params = {};
      if (this.form.versionType === 1) {
        params = {
          levelName: this.form.levelName,
          maxApps: this.maxApps ? -1 : this.form.maxApps,
          maxUsers: this.maxUsers ? -1 : this.form.maxUsers,
          price: this.price ? "面议" : this.form.price,
          trialVersion: this.form.trialVersion ? 1 : 0,
          versionType: this.form.versionType,
        };
      } else {
        params = {
          levelName: this.form.levelName,
          maxCpu: this.maxCpu ? -1 : this.form.maxCpu,
          maxMemory: this.maxMemory ? -1 : this.form.maxMemory,
          maxStorage: this.maxStorage ? -1 : this.form.maxStorage,
          price: this.price ? "面议" : this.form.price,
          trialVersion: this.form.trialVersion ? 1 : 0,
          versionType: this.form.versionType,
        };
      }

      // let params = {
      //   discount: this.form.discount,
      //   levelName: this.form.levelName,
      //   maxApps: this.maxApps ? -1 : this.form.maxApps,
      //   maxCpu: this.maxCpu ? -1 : this.form.maxCpu,
      //   maxMemory: this.maxMemory ? -1 : this.form.maxMemory,
      //   maxStorage: this.maxStorage ? -1 : this.form.maxStorage,
      //   maxUsers: this.maxUsers ? -1 : this.form.maxUsers,
      //   price: this.price ? "面议" : this.form.price,
      //   versionType: this.form.versionType,
      // };
      sassGradeApi.addGrade(params).then((res) => {
        if (res.success) {
          this.confirmLoading = false;
          this.$emit("handleOk");
        } else {
          this.confirmLoading = false;
          this.$message.error(res.errorMsg);
          // this.$emit("handleCancel");
        }
      });
    },

    //编辑等级
    editGrade() {
      this.confirmLoading = true;
      let params = {};
      if (this.form.versionType === 1) {
        params = {
          id: this.config.id,
          levelName: this.form.levelName,
          maxApps: this.maxApps ? -1 : this.form.maxApps,
          maxUsers: this.maxUsers ? -1 : this.form.maxUsers,
          price: this.price ? "面议" : this.form.price,
          versionType: this.form.versionType,
          rowVersion: this.rowVersion,
          trialVersion: this.form.trialVersion ? 1 : 0,
          levelStatus: this.levelStatus,
        };
      } else {
        params = {
          id: this.config.id,
          levelName: this.form.levelName,
          maxCpu: this.maxCpu ? -1 : this.form.maxCpu,
          maxMemory: this.maxMemory ? -1 : this.form.maxMemory,
          maxStorage: this.maxStorage ? -1 : this.form.maxStorage,
          price: this.price ? "面议" : this.form.price,
          versionType: this.form.versionType,
          rowVersion: this.rowVersion,
          trialVersion: this.form.trialVersion ? 1 : 0,
          levelStatus: this.levelStatus,
        };
      }

      sassGradeApi.editGrade(params).then((res) => {
        if (res.success) {
          this.confirmLoading = false;
          this.$emit("handleOk");
        } else {
          this.confirmLoading = false;
          this.$message.error(res.errorMsg);
          // this.$emit("handleCancel");
        }
      });
    },

    //修改租户状态
    delGrade() {
      this.confirmLoading = true;
      let id = this.config.id;
      sassGradeApi.delGrade(id).then((res) => {
        if (res.success) {
          this.confirmLoading = false;
          this.$emit("handleOk");
        } else {
          this.confirmLoading = false;
          this.$message.error(res.errorMsg);
          this.$emit("handleCancel");
        }
      });
    },

    handleOk() {
      if (this.config.type === "add") {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.addGrade();
          } else {
            return false;
          }
        });
      } else if (this.config.type === "edit") {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.editGrade();
          } else {
            return false;
          }
        });
      } else {
        this.delGrade();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form_item_contain {
  display: flex;
}
.form_item_span {
  margin-right: 20px;
}
.form_item_left {
  margin-right: 10px;
  // background-color: aqua;
  min-width: 110px;
}
.input_width {
  width: 350px;
}
.carriage {
  display: flex;
  width: 400px;
  // background-color: aqua;
  margin: auto;
  justify-content: space-between;
}
.site {
  width: 180px;
  height: 40px;
  line-height: 40px;
  // background-color: red;
}
.right {
  text-align: right;
}
.notice_text {
  color: #33333357;
  font-size: 12px;
  margin-bottom: 10px;
  font-weight: 900;
}

.modal_text {
  text-align: left;
  font-weight: 600;
  height: 30px;
  line-height: 30px;
  img {
    margin-bottom: 4px;
  }
  .modal_logo {
    margin-left: 10px;
  }
}

.modal_stext {
  color: rgb(136, 131, 131);
  font-size: 12px;
  padding-left: 30px;
  font-weight: 200;
  height: 60px;
}
/deep/.ant-table-body {
  overflow-x: hidden;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面的滑块*/
    border-radius: 5px;
    background: rgba(62, 63, 65, 0.5);
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道背景*/
    // display: none;
    width: 1px;
    padding: 0px;
    border-radius: 1px;
    background: rgba(0, 0, 0, 0.1);
  }
}

/deep/.ant-table-header {
  min-width: 0px !important;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
  }
}
</style>
<style>
.clickRowStyl {
  background-color: #52bbdb;
}

.ant-table-tbody > .clickRowStyl:hover > td {
  background-color: #00ed20;
}
</style>