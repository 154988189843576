import axios from "@/config/http";

export const sassGradeApi = {
  // 获取账号管理table数据
  getTableData(params) {
    return axios.get("/cloud-platform-backend-facade/front-api/v1/tenant/level", {
      params,
    });
  },

  //启用租户等级
  enableGrade(params) {
    return axios.post(
      "/cloud-platform-backend-facade/front-api/v1/tenant/level/enable",
      params
    );
  },
   //停用租户等级
   disableGrade(params) {
    return axios.post(
      "/cloud-platform-backend-facade/front-api/v1/tenant/level/disable",
      params
    );
  },
    //创建等级
    addGrade(params) {
      return axios.post(
        "/cloud-platform-backend-facade/front-api/v1/tenant/level",
        params
      );
    },
  // 修改等级
  editGrade(params) {
    return axios.put(
      "/cloud-platform-backend-facade/front-api/v1/tenant/level",
      params
    );
  },
  //删除等级
  delGrade(params) {
    return axios.delete(
      `/cloud-platform-backend-facade/front-api/v1/tenant/level/${params}`
    );
  },
};
