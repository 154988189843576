<template>
  <div>
    <!-- <div class="search_container">
      <div>
        <span>域名前缀：</span>
        <a-input
          @keydown.native.enter="handleSearch"
          class="input_width"
          placeholder="请输入"
          v-model="domainPrefix"
        />
      </div>
      <div>
        <span>版本类型：</span>
        <a-select v-model="versionType" class="input_width">
          <a-select-option
            v-for="(item, index) in versionTypeList"
            :key="index"
            :value="item.value"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div>
        <span>租户等级：</span>
        <a-select @focus="getLevelList" v-model="levelId" class="input_width">
          <a-select-option
            v-for="(item, index) in levelIdList"
            :key="index"
            :value="item.id"
          >
            {{ item.levelName }}
          </a-select-option>
        </a-select>
      </div>
      <div>
        <span>状态：</span>
        <a-select v-model="tenantStatus" class="input_width">
          <a-select-option
            v-for="(item, index) in tenantStatusList"
            :key="index"
            :value="item.value"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
    </div>
    <div class="search_container add_button">
      <div>
        <span>租户所有者：</span>
        <a-input
          @keydown.native.enter="handleSearch"
          class="input_width"
          placeholder="请输入"
          v-model="tenantOwner"
        />
      </div>
      <div>
        <span>租户到期时间：</span>

        <a-range-picker
          v-model="expireDate"
          class="input_width"
          @change="onTimeChange"
        />
      </div>
      <div class="blank_contain"></div>
      <div class="blank_contain" style="text-align: right">
        <a-button @click="reset"> 重置 </a-button>
        <a-button
          style="margin-left: 20px"
          @click="handleSearch"
          type="primary"
        >
          查询
        </a-button>
      </div>
    </div> -->
    <div>
      <a-button @click="openModel('add')" type="primary"> 创建等级 </a-button>
    </div>
    <div style="margin-top: 20px">
      <a-table
        :loading="loading"
        style="min-width: 1500px"
        :pagination="pagination"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="dataTable"
      >
        <span slot="versionType" slot-scope="text">
          <span v-if="text === 1">标准版</span>
          <span v-if="text === 2">专业版</span>
        </span>
        <span slot="maxUsers" slot-scope="text">
          <span v-if="text === -1">面议</span>
          <span v-else>{{ text }}</span>
        </span>
        <span slot="maxApps" slot-scope="text">
          <span v-if="text === -1">面议</span>
          <span v-else>{{ text }}</span>
        </span>
        <span slot="maxStorage" slot-scope="text">
          <span v-if="text === -1">面议</span>
          <span v-else>{{ text }}</span>
        </span>

        <span slot="action" slot-scope="text, record">
          <a @click="openModel('look', record)">查看</a>
          <a
            v-if="record.levelStatus === 0"
            style="margin-left: 20px"
            @click="enableGrade(record)"
            >启用</a
          >
          <a
            v-if="record.levelStatus === 1"
            style="margin-left: 20px"
            @click="disableGrade(record)"
            >停用</a
          >
          <a
            v-if="record.levelStatus === 0"
            style="margin-left: 20px"
            @click="openModel('edit', record)"
            >编辑</a
          >
          <a
            v-if="record.levelStatus === 0"
            style="margin-left: 20px"
            @click="openModel('del', record)"
          >
            删除
          </a>
        </span>
      </a-table>
    </div>
    <ModelTag
      v-if="modelConfig.show"
      @handleCancel="handleCancel"
      @handleOk="handleOk"
      :config="modelConfig"
    ></ModelTag>
  </div>
</template>

<script>
import pagination from "@/mixins/Pagination";
import ModelTag from "./model.vue";
import { sassGradeApi } from "@/api/sassGrade.js";

export default {
  mixins: [pagination],
  components: {
    ModelTag,
  },
  data() {
    return {
      loading: false,
      modelConfig: {
        type: "add",
        id: "",
        show: false,
        record: null,
        title: "",
        width: "60%",
        okText: "保存",
        delArr: [],
      },
      versionTypeList: [
        {
          name: "标准版",
          value: 1,
        },
        {
          name: "专业版",
          value: 2,
        },
      ],
      tenantStatusList: [
        {
          name: "已停用",
          value: 0,
        },
        {
          name: "启用中",
          value: 1,
        },
      ],
      levelIdList: [],
      columns: [
        {
          title: "等级名称",
          dataIndex: "levelName",
          key: "levelName",
          align: "center",
        },

        {
          title: "版本类型",
          dataIndex: "versionType",
          key: "versionType ",
          align: "center",
          scopedSlots: { customRender: "versionType" },
          // width: "110px",
        },
        // {
        //   title: "用户数上限",
        //   dataIndex: "maxUsers",
        //   key: "maxUsers",
        //   align: "center",
        //   scopedSlots: { customRender: "maxUsers" },
        //   // width: "160px",
        // },
        // {
        //   title: "外部应用数上限",
        //   dataIndex: "maxApps",
        //   key: "maxApps",
        //   align: "center",
        //   scopedSlots: { customRender: "maxApps" },
        //   // width: "160px",
        // },
        // {
        //   title: "存储空间上限(G)",
        //   dataIndex: "maxStorage",
        //   key: "maxStorage",
        //   align: "center",
        //   scopedSlots: { customRender: "maxStorage" },
        // },

        {
          title: "价格(/月)",
          dataIndex: "price",
          key: "price",
          align: "center",
          scopedSlots: { customRender: "price" },
        },

        {
          title: "操作",
          align: "center",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      dataTable: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    enableGrade(record) {
      let params = {
        id: record.id,
        levelName: record.levelName,
        levelStatus: 1,
        rowVersion: record.rowVersion,
        trialVersion: record.trialVersion,
        versionType: record.versionType,
      };
      sassGradeApi.enableGrade(params).then((res) => {
        if (res.success) {
          this.loading = false;
          // this.pagination.total = Number(res.data.total);
          this.getData();
        } else {
          this.loading = false;
          this.$message.error(res.errorMsg);
        }
      });
    },
    disableGrade(record) {
      let that=this
      let params = {
        id: record.id,
        levelName: record.levelName,
        levelStatus: 2,
        rowVersion: record.rowVersion,
        trialVersion: record.trialVersion,
        versionType: record.versionType,
      };
      sassGradeApi.disableGrade(params).then((res) => {
        if (res.success) {
          this.loading = false;
          this.$message.success("停用成功！")
          // this.pagination.total = Number(res.data.total);
          that.getData();
        } else {
          this.loading = false;
          this.$message.error(res.errorMsg);
        }
      });
    },
    // //时间修改事件
    // onTimeChange(dates, dateStrings) {
    //   this.expireDate = dateStrings;
    //   this.startExpireDate = dateStrings[0];
    //   this.endExpireDate = dateStrings[1];
    // },
    // //重置
    // reset() {
    //   this.tenantStatus = "";
    //   this.startExpireDate = "";
    //   this.endExpireDate = "";
    //   this.domainPrefix = "";
    //   this.versionType = "";
    //   this.levelId = "";
    //   this.expireDate = "";
    //   this.tenantOwner = "";
    //   this.pagination.current = 1;
    //   this.pagination.pageSize = 10;
    //   this.getData();
    // },
    //查询数据接口
    getData() {
      this.loading = true;

      let params={
          page: this.pagination.current,
        size: this.pagination.pageSize,
      }
      sassGradeApi.getTableData(params).then((res) => {
        if (res.success) {
          this.loading = false;
          this.pagination.total = Number(res.data.total);
          this.dataTable = res.data.records;
          console.log("没走吗22")
        } else {
          this.loading = false;
          this.$message.error(res.errorMsg);
        }
      });
    },
    // //查询
    // handleSearch() {
    //   this.pagination.current = 1;
    //   this.getData();
    // },

    //调用弹框事件
    openModel(type, record) {
      this.modelConfig.id = record ? record.id : null;
      this.modelConfig.type = type;
      this.modelConfig.show = true;
      if (type === "add") {
        this.modelConfig.title = "创建等级";
        this.modelConfig.okText = "确定";
        this.modelConfig.width = "600px";
      } else if (type === "del") {
        this.modelConfig.record = record;
        this.modelConfig.title = "删除等级";
        this.modelConfig.width = "600px";
        this.modelConfig.okText = "确定";
      } else if (type === "look") {
        this.modelConfig.record = record;
        this.modelConfig.title = "查看等级";
        this.modelConfig.width = "600px";
        this.modelConfig.okText = "关闭";
      }else {
        this.modelConfig.record = record;
        this.modelConfig.title = "编辑等级";
        this.modelConfig.width = "600px";
        this.modelConfig.okText = "确定";
      }
    },

    //弹框关闭
    handleCancel() {
      this.modelConfig.show = false;
    },
    //弹框保存按钮
    handleOk() {
      this.modelConfig.show = false;
      if (this.modelConfig.type === "open") {
        this.$message.success("启用成功！");
      } else if (this.modelConfig.type === "close") {
        this.$message.success("停用成功！");
      } else {
        this.$message.success("保存成功！");
      }

      this.getData();
    },
    // 分页改变
    onSizeChange(current, size) {
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.getData();
    },
    //分页
    onPage(current) {
      this.pagination.current = current;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.dateStyle {
  color: red;
}
.input_width {
  width: 240px;
}
.blank_contain {
  width: 340px;
}
.search_container {
  display: flex;
  justify-content: space-between;
  min-width: 1500px;
  overflow: auto;
  // &>div{
  //   // width: 25%;
  // }
  & > div > span:first-child {
    // background-color: aqua;
    display: inline-block;
    width: 100px;
    text-align: right;
  }
}
.add_button {
  margin-top: 20px;
  margin-bottom: 20px;
}
.popover_contain {
  height: 30px;
  width: 120px;
  cursor: pointer;
  line-height: 30px;
  text-align: center;
  border-radius: 4px;
}
.popover_contain:hover {
  background-color: #f3f3f3;
}
</style>